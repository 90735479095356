import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const SimpleCtaSmall = ({
	heading,
	content,
	btnCta1,
	btnCta2,
	bgImage,
	textColor = "#252525",
}) => {
	return (
		<BgImage image={getImage(bgImage)} className="py-8 position-relative">
			<div
				style={{
					zIndex: 1,
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%)`,
				}}
				className="position-absolute w-100 h-100 top-0 start-0"
			></div>
			<Container
				style={{
					zIndex: 2,
				}}
				className="position-relative"
			>
				<Row className={`align-items-center justify-content-lg-between`}>
					<Col lg={7} className={`mb-4 mb-lg-0`}>
						<h3 className="my-0 fs-1" style={{ color: textColor }}>
							{heading}
						</h3>
						<div
							className="pt-4"
							style={{ color: textColor }}
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</Col>
					<Col lg={4}>
						<div
							className={`d-flex align-items-lg-center flex-column flex-md-row justify-content-md-end `}
						>
							{btnCta1 && btnCta1.url && (
								<Button
									as={Link}
									href={btnCta1.url}
									size="lg"
									className="px-4 py-3 me-md-3 montserrat-bold fs-6 mb-3 mb-md-0 text-light-black"
									target={btnCta1.target}
									variant="secondary"
								>
									{btnCta1.title}
								</Button>
							)}

							{btnCta2 && btnCta2.url && (
								<Button
									as={Link}
									href={btnCta2.url}
									size="lg"
									className="px-4 py-3 montserrat-bold fs-6 bg-transparent"
									style={{
										color: textColor,
										border: `1px solid ${textColor}`,
									}}
									target={btnCta2.target}
								>
									{btnCta2.title}
								</Button>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export const sectionWrapper = (imgUrl, isGreen) => ({
	background: isGreen
		? `linear-gradient(0deg, rgba(54, 180, 159, 0.60) 0%, rgba(54, 180, 159, 0.60) 100%), url(${imgUrl}) lightgray 0 / cover no-repeat`
		: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${imgUrl}) lightgray 50% / cover no-repeat`,
});

export default SimpleCtaSmall;
