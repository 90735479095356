import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

import { Link } from "gatsby";
import { BgImage } from "gbimage-bridge";
import Button from "react-bootstrap/Button";
import { IoIosArrowBack } from "react-icons/io";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";
import CaseStudyPhase from "../components/case-study-phase";
import ClientTestimonials from "../components/client-testimonials";
import SimpleCtaSmall from "../components/simple-cta-small";
import TwoColumnContentWithBottomImage from "../components/two-column-content-with-bottom-image";
import TwoColumnImageContent from "../components/two-column-image-content";
import DownLoadIcon from "../images/download.svg";
import VideoIcon from "../images/video.svg";
import { checkPropertiesForNull } from "../utils";

const CaseStudyPostTemplate = ({ data: { post, site, bgImage } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Case Studies",
				item: {
					url: `${siteUrl}/case-studies`,
					id: `${siteUrl}/case-studies`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/case-studies/${post.slug}`,
					id: `${siteUrl}/case-studies/${post.slug}`,
				},
			},
		],
	};

	const { caseStudyPost } = post;

	const catName = (catList) => {
		let outStr = "";
		if (catList.length > 0) {
			catList.forEach(
				(ele, idx) =>
					(outStr += `${ele.name} ${idx < catList.length ? "" : ", "}`)
			);
		}

		return outStr;
	};

	const [showPlayBtn, setShowPlayBtn] = useState(true);

	const vidRef = useRef(null);
	const handlePlayVideo = () => {
		vidRef.current.play();
		setShowPlayBtn(!showPlayBtn);
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.seoFields?.title}
				description={post.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/case-studies/${post.slug}`,
					title: `${post.seoFields?.opengraphTitle}`,
					description: `${post.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${post.seoFields?.image?.node.sourceUrl}`,
							width: `${post.seoFields?.image?.node.mediaDetails.width}`,
							height: `${post.seoFields?.image?.node.mediaDetails.height}`,
							alt: `${post.seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>
			<Layout isDefault={false}>
				<BgImage
					image={getImage(bgImage.childImageSharp.gatsbyImageData)}
					className="py-5 py-md-8 position-relative"
				>
					<div
						style={{
							zIndex: 1,
							background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%)`,
						}}
						className="position-absolute w-100 h-100 top-0 start-0"
					></div>
					<Container style={{ zIndex: 2 }} className="position-relative">
						<Row className="align-items-lg-center justify-content-start">
							<Col lg={12} className="mb-4 mb-lg-0">
								<Button
									as={Link}
									to={`/case-studies`}
									size="lg"
									className="mb-5 p-0 text-white montserrat-medium fs-6 bg-transparent border-0"
								>
									<IoIosArrowBack className="fs-5 me-2" />
									Case Studies
								</Button>
								{post.terms?.nodes.length > 0 && (
									<span
										className="bg-lighter-grey py-1 px-2 rounded d-block"
										style={{ width: "fit-content" }}
									>
										{catName(post.terms?.nodes)}
									</span>
								)}
								<h2 className="my-auto text-white mt-2 d-flex align-items-center">
									{caseStudyPost?.caseStudyPostBanner?.caseStudyBannerHeading}
									{caseStudyPost?.caseStudyPostBanner?.caseStudyPdf && (
										<Button
											as={Link}
											href={
												caseStudyPost?.caseStudyPostBanner?.caseStudyPdf?.node
													?.mediaItemUrl
											}
											className="border-0 bg-white rounded-5 p-0 d-flex align-items-center justify-content-center ms-3"
											style={{ width: "32px", height: "32px" }}
										>
											<DownLoadIcon />
										</Button>
									)}
								</h2>
								<div className="position-relative text-center mt-4 mt-md-6 case-study-banner">
									<div className="case-study-img1">
										<GatsbyImage
											image={
												caseStudyPost?.caseStudyPostBanner
													?.caseStudyBannerImage1.node.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={
												caseStudyPost?.caseStudyPostBanner
													?.caseStudyBannerImage1.node?.altText
											}
											className="border-2 border-white rounded-5 border mx-auto"
											style={{ maxHeight: "720px", width: "80%" }}
										/>
									</div>
									{caseStudyPost?.caseStudyPostBanner
										?.caseStudyBannerImage2 && (
										<div
											className="case-study-img1 start-0"
											style={{ bottom: "100px" }}
										>
											<GatsbyImage
												image={
													caseStudyPost?.caseStudyPostBanner
														?.caseStudyBannerImage2.node.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={
													caseStudyPost?.caseStudyPostBanner
														?.caseStudyBannerImage2.node?.altText
												}
												className=" border-2 border-white rounded-5 border"
												style={{ width: "100%", height: "100%" }}
											/>
										</div>
									)}

									{caseStudyPost?.caseStudyPostBanner
										?.caseStudyBanner3Options[0] === "Image" ? (
										<>
											{caseStudyPost?.caseStudyPostBanner
												?.caseStudyBanner3Image && (
												<div
													className="case-study-img1 end-0"
													style={{ top: "100px" }}
												>
													<GatsbyImage
														image={
															caseStudyPost?.caseStudyPostBanner
																?.caseStudyBanner3Image.node.localFile
																.childImageSharp.gatsbyImageData
														}
														alt={
															caseStudyPost?.caseStudyPostBanner
																?.caseStudyBanner3Image.node?.altText
														}
														className="border-2 border-white rounded-5 border"
														style={{ width: "100%", height: "100%" }}
													/>
												</div>
											)}
										</>
									) : (
										<>
											{caseStudyPost?.caseStudyPostBanner
												.caseStudyBanner3VideoOptions === "Upload" ? (
												<>
													{caseStudyPost?.caseStudyPostBanner
														.caseStudyBanner3UploadVideo.node.mediaItemUrl && (
														<div
															className="case-study-img1 end-0"
															style={{ top: "100px" }}
														>
															<LazyLoad height={200} offset={100}>
																<video
																	ref={vidRef}
																	width="100%"
																	controls
																	height="auto"
																	className="border-2 border-white rounded-5 border"
																>
																	<source
																		src={
																			caseStudyPost?.caseStudyPostBanner
																				.caseStudyBanner3UploadVideo.node
																				.mediaItemUrl
																		}
																		type="video/mp4"
																	/>
																</video>

																{showPlayBtn && (
																	<VideoIcon
																		onClick={handlePlayVideo}
																		className="position-absolute mx-auto h-100 start-0 end-0 top-0 bottom-0"
																		style={{ width: "40px", height: "40px" }}
																	/>
																)}
															</LazyLoad>
														</div>
													)}
												</>
											) : (
												<>
													{caseStudyPost?.caseStudyPostBanner
														?.caseStudyBanner3YoutubeVideo && (
														<div
															className="case-study-img1 end-0"
															style={{ top: "100px" }}
														>
															<LazyLoad height={200} offset={100}>
																<div
																	style={{
																		overflow: "hidden",
																		width: "100%",
																		height: "auto",
																	}}
																	className="border-2 border-white rounded-5 border  "
																>
																	<ReactPlayer
																		width="100%"
																		controls
																		url={
																			caseStudyPost?.caseStudyPostBanner
																				?.caseStudyBanner3YoutubeVideo
																		}
																	/>
																</div>
															</LazyLoad>
														</div>
													)}
												</>
											)}
										</>
									)}
								</div>
								{/* <div className="d-flex mt-5">
                  {post.caseStudyPost?.caseStudyPostBanner
                    .caseStudyBannerAuthorName && (
                    <div className="d-flex flex-column text-white me-5">
                      <span className="montserrat-light">Written By</span>
                      <span>
                        {
                          post.caseStudyPost?.caseStudyPostBanner
                            .caseStudyBannerAuthorName
                        }
                      </span>
                    </div>
                  )}

                  <div className="d-flex flex-column text-white">
                    <span className="montserrat-light">Published On</span>
                    <span>{post.date}</span>
                  </div>
                </div> */}
							</Col>
						</Row>
					</Container>
				</BgImage>
				{caseStudyPost?.caseStudyTestimonials.selectTestimonials && (
					<ClientTestimonials
						testimonials={
							caseStudyPost?.caseStudyTestimonials.selectTestimonials?.nodes
						}
					/>
				)}
				{!checkPropertiesForNull(
					caseStudyPost?.caseStudyTwoColumnHeadingContent,
					["twoColumnHeading", "twoColumnContent"]
				) && (
					<TwoColumnContentWithBottomImage
						heading={
							caseStudyPost?.caseStudyTwoColumnHeadingContent.twoColumnHeading
						}
						content={
							caseStudyPost?.caseStudyTwoColumnHeadingContent.twoColumnContent
						}
						textColor="#252525"
					/>
				)}
				{caseStudyPost?.caseStudyPhaseSection && (
					<CaseStudyPhase
						noLines
						heading={
							caseStudyPost?.caseStudyPhaseSection?.caseStudyPhaseHeading
						}
						subHeading={
							caseStudyPost?.caseStudyPhaseSection?.caseStudyPhaseSubHeading
						}
						notice={caseStudyPost?.caseStudyPhaseSection?.caseStudyPhaseNotice}
						content={
							caseStudyPost?.caseStudyPhaseSection?.caseStudyPhaseContent
						}
						textAlign={
							caseStudyPost?.caseStudyPhaseSection
								?.caseStudyPhaseTextAlignment[0]
						}
						resultText={
							caseStudyPost?.caseStudyPhaseSection?.caseStudyPhaseResultText
						}
						bottomNotice={
							caseStudyPost?.caseStudyPhaseSection?.caseStudyPhaseBottomNotice
						}
						phases={caseStudyPost?.caseStudyPhaseSection?.caseStudyPhases}
						bgImage={
							caseStudyPost?.caseStudyPhaseSection
								?.caseStudyPhaseBackgroundImage.node.localFile.childImageSharp
								.gatsbyImageData
						}
					/>
				)}
				{!checkPropertiesForNull(caseStudyPost?.caseStudyTwoColumnImage, [
					"caseStudyTwoColumnHeading",
					"caseStudyTwoColumnContent",
				]) && (
					<TwoColumnImageContent
						heading={
							caseStudyPost?.caseStudyTwoColumnImage.caseStudyTwoColumnHeading
						}
						content={
							caseStudyPost?.caseStudyTwoColumnImage.caseStudyTwoColumnContent
						}
						image={
							caseStudyPost?.caseStudyTwoColumnImage.caseStudyTwoColumnImage
								.node
						}
						bgColor={
							caseStudyPost?.caseStudyTwoColumnImage
								.twoColumnImageBackgroundColor
						}
						textColor={"#000"}
					/>
				)}
				{caseStudyPost?.caseStudyPhaseSection3 && (
					<CaseStudyPhase
						heading={
							caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhaseHeading
						}
						subHeading={
							caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhaseSubHeading
						}
						notice={caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhaseNotice}
						content={
							caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhaseContent
						}
						textAlign={
							caseStudyPost?.caseStudyPhaseSection3
								?.caseStudyPhaseTextAlignment[0]
						}
						resultText={
							caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhaseResultText
						}
						bottomNotice={
							caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhaseBottomNotice
						}
						phases={caseStudyPost?.caseStudyPhaseSection3?.caseStudyPhases}
						bgImage={
							caseStudyPost?.caseStudyPhaseSection3
								?.caseStudyPhaseBackgroundImage.node.localFile.childImageSharp
								.gatsbyImageData
						}
					/>
				)}
				{caseStudyPost?.caseStudyCta && (
					<SimpleCtaSmall
						heading={caseStudyPost?.caseStudyCta.caseStudyCtaHeading}
						content={caseStudyPost?.caseStudyCta.caseStudyCtaContent}
						btnCta1={caseStudyPost?.caseStudyCta.caseStudyCtaButton1}
						btnCta2={caseStudyPost?.caseStudyCta.caseStudyCtaButton2}
						bgImage={
							caseStudyPost?.caseStudyCta.caseStudyCtaBackgroundImage?.node
								.localFile.childImageSharp.gatsbyImageData
						}
						textColor="#fff"
					/>
				)}
			</Layout>
		</>
	);
};

export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export default CaseStudyPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		bgImage: file(relativePath: { eq: "common-bg.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					formats: WEBP
					quality: 50
					transformOptions: { cropFocus: CENTER, fit: COVER }
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
		}

		post: wpCaseStudy(id: { eq: $id }) {
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
				localBusinessSchema
				image {
					node {
						altText
						sourceUrl
						mediaDetails {
							height
							width
						}
					}
				}
			}
			title
			slug
			date(formatString: "DD MMMM, YYYY")
			terms {
				nodes {
					slug
					name
				}
			}
			caseStudyPost {
				caseStudyPostBanner {
					caseStudyBannerHeading
					caseStudyBannerAuthorName
					caseStudyBannerSubHeading
					caseStudyPublishedDate
					caseStudyPdf {
						node {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					caseStudyBannerImage1 {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					caseStudyBannerImage2 {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					caseStudyBanner3Options
					caseStudyBanner3Image {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					caseStudyBanner3VideoOptions
					caseStudyBanner3UploadVideo {
						node {
							mediaItemUrl
						}
					}
					caseStudyBanner3YoutubeVideo
				}
				caseStudyTestimonials {
					selectTestimonials {
						nodes {
							... on WpReview {
								title
								date(formatString: "DD MMMM, YYYY")
								reviewPostFields {
									reviewAuthor
									reviewAuthorProfilePicture {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
														width: 56
													)
												}
											}
										}
									}
									reviewQuote
									reviewStarSelect
									reviewAuthorCompanyPicture {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
														width: 80
													)
												}
											}
										}
									}
									reviewAuthorDesignation
								}
								terms {
									nodes {
										taxonomyName
										name
									}
								}
							}
						}
					}
				}
				caseStudyTwoColumnHeadingContent {
					twoColumnHeading
					twoColumnContent
				}
				caseStudyPhaseSection {
					caseStudyPhaseHeading
					caseStudyPhaseSubHeading
					caseStudyPhaseTextAlignment
					caseStudyPhaseContent
					caseStudyPhaseNotice
					caseStudyPhaseResultText
					caseStudyPhaseBottomNotice
					caseStudyPhaseBackgroundImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					caseStudyPhases {
						caseStudyNumberHeading
						caseStudyNumberContent
						caseStudyNumberCardHeading
						caseStudyNumberCardContent
						caseStudyPercentageNumber
					}
				}
				caseStudyTwoColumnImage {
					caseStudyTwoColumnHeading
					caseStudyTwoColumnContent
					twoColumnImageBackgroundColor
					caseStudyTwoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				caseStudyPhaseSection3 {
					caseStudyPhaseHeading
					caseStudyPhaseSubHeading
					caseStudyPhaseContent
					caseStudyPhaseNotice
					caseStudyPhaseBottomNotice
					caseStudyPhaseResultText
					caseStudyPhaseTextAlignment
					caseStudyPhaseBackgroundImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					caseStudyPhases {
						caseStudyNumberHeading
						caseStudyNumberContent
						caseStudyNumberCardHeading
						caseStudyNumberCardContent
						caseStudyPercentageNumber
					}
				}
				caseStudyCta {
					caseStudyCtaHeading
					caseStudyCtaContent
					caseStudyCtaButton1 {
						title
						target
						url
					}
					caseStudyCtaButton2 {
						title
						target
						url
					}
					caseStudyCtaBackgroundImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
