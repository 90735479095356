import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const CaseStudyPhase = ({
	heading,
	subHeading,
	notice,
	content,
	textAlign,
	resultText,
	phases,
	bottomNotice,
	bgImage,
	noLines,
}) => {
	const alignItem = textAlign === "Left" ? "start" : "center";
	const [hoverIndex, setHoverIndex] = useState(null);
	const bgColorVal = (val) => {
		if (val === 2) {
			return "#36B75A";
		} else if (val === 0) {
			return "#43AC96";
		} else {
			return "#E1B528";
		}
	};

	const isSubphaseHeading = () => {
		return phases.some((e) => e.caseStudyNumberCardHeading);
	};
	return (
		<BgImage image={getImage(bgImage)} className="py-8 position-relative">
			<div
				style={{
					zIndex: 1,
					background: `linear-gradient(0deg, rgba(11, 64, 50, 0.95) 0%, rgba(11, 64, 50, 0.95) 100%)`,
				}}
				className="position-absolute w-100 h-100 top-0 start-0"
			></div>
			<Container style={{ zIndex: 2 }} className="position-relative">
				<Row className={`align-items-lg-center justify-content-${alignItem}`}>
					<Col lg={10} className="mb-4 mb-lg-0 text-white">
						<div className={`text-${alignItem}`}>
							<h2 className="my-auto text-white m-0">{heading}</h2>
							<h4 className="pt-4 mb-0">{subHeading}</h4>
							<span className="montserrat-light">{notice}</span>
						</div>

						{content && (
							<div
								className="pt-4"
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
						)}
					</Col>
				</Row>
				<div style={{ zIndex: 3 }} className="  d-md-none  position-relative">
					{isSubphaseHeading() && (
						<Row className="justify-content-center">
							<Col xs={12} className=" mb-lg-0 pt-4 text-white">
								<Row
									style={{
										paddingBottom: "30px",
									}}
									className="rounded-3  gx-3 "
								>
									{phases &&
										phases.map(
											(item, i) =>
												i === 0 && ( // Check if the index is greater than 0
													<Col xs={6}>
														<div
															className={`phase-item-content ${
																hoverIndex === i
																	? `phase-card-${i}`
																	: "bg-dark-green"
															}  text-white p-4 rounded-5 position-relative`}
														>
															<h5
																style={{ fontSize: "70%" }}
																className="pb-0 mb-0"
															>
																Scope {i + 1}
															</h5>
															<h4 className="fs-6">
																{item.caseStudyNumberCardHeading}
															</h4>
															<p style={{ fontSize: "80%" }} className="mb-0">
																{item.caseStudyNumberCardContent}
															</p>
														</div>
													</Col>
												)
										)}
								</Row>
							</Col>
						</Row>
					)}
				</div>
				<div
					style={{ zIndex: 3 }}
					className=" d-none d-md-block d-xl-none position-relative"
				>
					{isSubphaseHeading() && (
						<Row className="justify-content-center">
							<Col xs={12} className=" mb-lg-0 pt-4 text-white">
								<Row
									style={{
										paddingBottom: "30px",
									}}
									className="rounded-3  gx-3 "
								>
									{phases &&
										phases.map(
											(item, i) =>
												i === 0 && ( // Check if the index is greater than 0
													<Col xs={6}>
														<div
															className={`phase-item-content ${
																hoverIndex === i
																	? `phase-card-${i}`
																	: "bg-dark-green"
															} text-white p-4 rounded-5 position-relative`}
														>
															<h5
																style={{ fontSize: "80%" }}
																className="pb-0 mb-0"
															>
																Scope {i + 1}
															</h5>
															<h4>{item.caseStudyNumberCardHeading}</h4>
															<p className="mb-0">
																{item.caseStudyNumberCardContent}
															</p>
														</div>
													</Col>
												)
										)}
								</Row>
							</Col>
						</Row>
					)}
				</div>
				<Row className="justify-content-center">
					<Col lg={12} xl={12} className=" mb-lg-0 text-white">
						<div className="mt-xl-6">
							<div className="phase-result-text d-flex align-items-center justify-content-center mb-4">
								<span>{resultText}</span>
							</div>
							<div className="d-flex rounded-3">
								{phases &&
									phases.map((item, i) => (
										<div
											style={{
												width: `${item.caseStudyPercentageNumber}`,

												backgroundColor: bgColorVal(i),
											}}
											onMouseEnter={() => setHoverIndex(i)}
											onMouseLeave={() => setHoverIndex(null)}
											className={`p-3 text-start phase-item-width text-light-black phase-item position-relative ${
												noLines ? "" : "add-lines"
											}`}
										>
											<p className="fs-6 mb-0 lh-1 montserrat-medium">
												{item.caseStudyNumberHeading}
											</p>
											<span className="montserrat-medium text-light-black">
												{item.caseStudyNumberContent}
											</span>
											<h5 className="fs-1 mb-0 lh-1 mt-1">
												{item.caseStudyPercentageNumber}
											</h5>
										</div>
									))}
							</div>
						</div>
					</Col>
				</Row>
				<div className="d-md-none">
					{isSubphaseHeading() && (
						<Row className="justify-content-center">
							<Col xs={12} className="mb-4 mb-lg-0 text-white">
								<Row
									style={{ paddingTop: "55px" }}
									className="rounded-3  mb-3 gx-3 "
								>
									{phases &&
										phases.map(
											(item, i) =>
												i > 0 && ( // Check if the index is greater than 0
													<Col xs={6}>
														<div
															className={`phase-item-content ${
																hoverIndex === i
																	? `phase-card-${i}`
																	: "bg-dark-green"
															} text-white p-4 rounded-5 position-relative `}
														>
															<h5
																style={{ fontSize: "70%" }}
																className="pb-0 mb-0"
															>
																Scope {i + 1}
															</h5>
															<h4 className="fs-6">
																{item.caseStudyNumberCardHeading}
															</h4>
															<p style={{ fontSize: "80%" }} className="mb-0">
																{item.caseStudyNumberCardContent}
															</p>
														</div>
													</Col>
												)
										)}
								</Row>
							</Col>
						</Row>
					)}
				</div>
				<div className=" d-none d-md-block d-xl-none">
					{isSubphaseHeading() && (
						<Row className="justify-content-center">
							<Col xs={12} className="mb-4 mb-lg-0 text-white">
								<Row
									style={{ paddingTop: "55px" }}
									className="rounded-3  mb-3 gx-3 "
								>
									{phases &&
										phases.map(
											(item, i) =>
												i > 0 && ( // Check if the index is greater than 0
													<Col xs={6}>
														<div
															className={`phase-item-content ${
																hoverIndex === i
																	? `phase-card-${i}`
																	: "bg-dark-green"
															} text-white p-4 rounded-5 position-relative`}
														>
															<h5
																style={{ fontSize: "80%" }}
																className="pb-0 mb-0"
															>
																Scope {i + 1}
															</h5>
															<h4>{item.caseStudyNumberCardHeading}</h4>
															<p className="mb-0">
																{item.caseStudyNumberCardContent}
															</p>
														</div>
													</Col>
												)
										)}
								</Row>
							</Col>
						</Row>
					)}
				</div>

				<div className="d-none d-xl-block">
					{isSubphaseHeading() && (
						<Row className="justify-content-center">
							<Col lg={12} className="mb-4 mb-lg-0 text-white">
								<Row
									style={{ paddingTop: "55px" }}
									className="rounded-3 mb-3 gx-3"
								>
									{phases &&
										phases.map((item, i) => (
											<>
												{item.caseStudyNumberCardHeading && (
													<Col lg={4}>
														<div
															className={`phase-item-content ${
																hoverIndex === i
																	? `phase-card-${i}`
																	: "bg-dark-green"
															} text-white p-4 rounded-5 position-reltive`}
														>
															<h5
																style={{ fontSize: "80%" }}
																className="pb-0 mb-0"
															>
																Scope {i + 1}
															</h5>
															<h4>{item.caseStudyNumberCardHeading}</h4>
															<p className="mb-0">
																{item.caseStudyNumberCardContent}
															</p>
														</div>
													</Col>
												)}
											</>
										))}
								</Row>
							</Col>
						</Row>
					)}
				</div>
				<Row className={`align-items-lg-center justify-content-center mt-5`}>
					<Col lg={7} className="mb-4 mb-lg-0 text-white">
						<p className=" text-center ">{bottomNotice}</p>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(11, 64, 50, 0.95) 0%, rgba(11, 64, 50, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export default CaseStudyPhase;
